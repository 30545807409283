import clsx from 'clsx';
import Image from 'next/image';
import { event } from 'nextjs-google-analytics';
import React from 'react';
import Fade from 'react-reveal/Fade';

import { RichTextBlock } from '@/components/Blocks/RichText';
import Button from '@/components/Primitive/Button/Button';

interface IRecruitmentBlock {
  title: string;
  image: string;
  description: any;
  ctaHref?: string;
  ctaLabel?: string;
  className?: string;
}

const RecruitmentBlock = ({
  title,
  image,
  description,
  ctaHref,
  ctaLabel,
  className,
}: IRecruitmentBlock) => {
  return (
    <div
      className={clsx(
        'container-md flex flex-col-reverse gap-4 md:flex-row md:items-center',
        className
      )}
    >
      <Fade left>
        <div className="flex flex-col items-center space-y-8 text-center md:basis-[40%] md:items-start md:text-left">
          <h2 className="text-2xl font-black uppercase text-cream">{title}</h2>
          <div className="space-y-4">
            <RichTextBlock
              className="text-lg text-cream"
              block={{
                __typename: 'RichText',
                id: 'RichText',
                content: description,
              }}
            />
          </div>
          <Button
            onClick={() =>
              event('click', {
                category: 'Homepage',
                label: 'Careers CTA Clicked',
              })
            }
            className="uppercase"
            href={ctaHref}
            target="_blank"
          >
            {ctaLabel}
          </Button>
        </div>
      </Fade>
      <div className="flex grow items-center justify-center md:basis-1/2">
        <Fade right>
          <Image
            src={image}
            width={600}
            height={600}
            alt="Careers"
            sizes="(min-width:768px) 50vw, 100vw"
          />
        </Fade>
      </div>
    </div>
  );
};

export default RecruitmentBlock;
