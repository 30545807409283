import Image from 'next/image';
import React from 'react';
import Fade from 'react-reveal/Fade';
import Pulse from 'react-reveal/Pulse';

import gridTextureImg from '@/assets/images/grid-texture.png';
import hybridGamesImg from '@/assets/images/hybrid-games-logo.png';
// type IHybridGamesIntro = {};

const HybridGamesIntro = () => {
  return (
    <div className="flex flex-col items-center space-y-4 text-center">
      <Pulse>
        <Image
          className="w-full max-w-[900px]"
          src={hybridGamesImg}
          alt="The Home of Hybrid Games"
          width={975}
          height={312}
          sizes="(min-width: 768px) 900px, 100vw"
        />
      </Pulse>
      <div className="!-mt-1 sm:-mt-4">
        <h2 className="sr-only">The Home of Hybrid Games</h2>
        <p className="text-lg font-medium text-blueAlt-500">
          One Destination. Every Genre.
        </p>
      </div>
      <Image
        className="z-10"
        src={gridTextureImg}
        alt=""
        width={1200}
        height={280}
        sizes="(min-width: 768px) 1200px, 100vw"
      />
      <Fade top cascade>
        <div className="container-md flex flex-wrap justify-center gap-6 pb-8">
          <div className="max-w-[300px] flex-col items-center space-y-6 text-center">
            <h3 className="font-orton text-2xl text-offWhite sm:text-3xl">
              GAMES
            </h3>
            <p className="text-lg font-medium text-blueAlt-500">
              Play any game, any time, in daily 24 hour tournaments, competing
              against your fellow players
            </p>
          </div>
          <div className="max-w-[300px] flex-col items-center space-y-6 text-center">
            <h3 className="font-orton text-2xl text-offWhite sm:text-3xl">
              SHOWS
            </h3>
            <p className="text-lg font-medium text-blueAlt-500">
              Tune into our shows, broadcasted inside the games, and go
              head-to-head against special guests
            </p>
          </div>
          <div className="max-w-[300px] flex-col items-center space-y-6 text-center">
            <h3 className="font-orton text-2xl text-offWhite sm:text-3xl">
              PRIZES
            </h3>
            <p className="text-lg font-medium text-blueAlt-500">
              Climb the Leaderboard, and win prizes through luck, or skill.
              <br />
              Every. Single. Day.
            </p>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default HybridGamesIntro;
