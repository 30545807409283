import { gql } from 'graphql-request';

interface Games {
  games: {
    id: string;
    title: string;
    sell: string;
    thumbnail: string;
    thumbnail2x: string;
    cardThumbnail: string;
    datePublished: string;
    genre: {
      id: string;
      name: string;
    }[];
    href: string;
  }[];
}

export const createGamesListQuery = () => gql`
  query getGamesList($genreId: String) {
    Games(sort: "-order", where: { genre: { equals: $genreId } }) {
      docs {
        id
        title
        sell
        slug
        datePublished
        genre {
          id
          name
        }
        thumbnail {
          sizes {
            gameThumbnail2x {
              url
            }
            gameThumbnail {
              url
            }
          }
        }
      }
    }
  }
`;

export const mapToGamesList = (data: any): Games => {
  return {
    games: data?.Games?.docs.map((item: any) => ({
      id: item.id,
      title: item.title,
      sell: item.sell || '',
      genre: item.genre || [],
      datePublished: item.datePublished || '',
      cardThumbnail: item?.cardThumbnail?.sizes?.gameThumbnail?.url || '',
      thumbnail: item.thumbnail?.sizes?.gameThumbnail?.url || '',
      thumbnail2x: item.thumbnail?.sizes?.gameThumbnail2x?.url || '',
      href: item.href || '',
    })),
  };
};
