import clsx from 'clsx';
import React from 'react';
import Marquee from 'react-fast-marquee';

import useQuery from '@/hooks/use-query';
import {
  createEventsQuery,
  mapToEventsContent,
} from '@/queries/globals/events';
import { getNextShow } from '@/utils/get-next-dates';

interface IEventsTicker {
  className?: string;
}

const eventsQuery = createEventsQuery();
const eventsQueryParams = {};

const EventsTicker = ({ className }: IEventsTicker) => {
  const { data } = useQuery(eventsQuery, eventsQueryParams, mapToEventsContent);
  if (!data?.events?.schedule) return null;
  const nextEvent = getNextShow(data?.events.schedule);

  const items = [nextEvent?.headline, `Next Arcade Show: ${nextEvent?.title}`];
  if (!items) return null;
  return (
    <div className={clsx('min-h-[36px]', className)}>
      <Marquee speed={50} gradient={false}>
        <div className="flex w-full justify-between">
          {items.map((item) => (
            <p
              key={item}
              className="text-glow px-[25vw] font-dharma text-2xl font-black uppercase italic tracking-wide text-white"
            >
              {item}
            </p>
          ))}
        </div>
      </Marquee>
    </div>
  );
};

export default EventsTicker;
