import { dehydrate, QueryClient } from '@tanstack/react-query';

import heroImg from '@/assets/images/hero.png';
import Arcade from '@/components/Core/Arcade/Arcade';
import BrandGrid from '@/components/Core/BrandGrid/BrandGrid';
import EventsTicker from '@/components/Core/EventsTicker/EventsTicker';
import FullWidthVideo from '@/components/Core/FullWidthVIdeo/FullWidthVideo';
import GameCarousel from '@/components/Core/GameCarousel/GameCarousel';
import Hero from '@/components/Core/Hero/Hero';
import HybridGamesIntro from '@/components/Core/HybridGamesIntro/HybridGamesIntro';
import LatestNewsCarousel from '@/components/Core/LatestNewsCarousel/LatestNewsCarousel';
import RecruitmentBlock from '@/components/Core/RecruitmentBlock/RecruitmentBlock';
import MainLayout from '@/components/Layout/Main';
import { CustomMeta } from '@/components/Meta/CustomMeta';
import useQuery, { makeQuery } from '@/hooks/use-query';
import { prefetchEvents } from '@/lib/prefetch-events';
import { createGamesListQuery, mapToGamesList } from '@/queries/games/list';
import {
  createHomepageQuery,
  mapToHomepageContent,
} from '@/queries/globals/homepage';
import { createNewsListQuery, mapToNewsList } from '@/queries/news/list';

const homeQuery = createHomepageQuery();
const homeQueryVars = {};

const gamesQuery = createGamesListQuery();
const gamesQueryVars = {};

const latestNewsQuery = createNewsListQuery();
const latestNewsQueryVars = {
  limit: 10,
};

const Index = () => {
  const { data } = useQuery(homeQuery, homeQueryVars, mapToHomepageContent);
  const { asSeenOn, arcade, featuredVideo, recruitment } = data || {};

  const { data: latestNewsData } = useQuery(
    latestNewsQuery,
    latestNewsQueryVars,
    mapToNewsList
  );

  const { data: gamesData } = useQuery(
    gamesQuery,
    gamesQueryVars,
    mapToGamesList
  );
  const { games } = gamesData || {};

  return (
    <MainLayout className="pt-4">
      <CustomMeta {...data?.seo} />
      <div className="pb-page">
        <Hero image={heroImg} ctaLabel="Play now" ctaHref="/play-now" />
        <div className="py-page space-y-page-lg mx-auto w-full overflow-hidden">
          {games && games?.length > 0 && (
            <GameCarousel items={games} className="py-10 sm:pb-0 sm:pt-8" />
          )}

          <div className="mx-auto !mt-6 md:!mt-12">
            <EventsTicker className="hidden md:block" />
            {featuredVideo?.videoId && (
              <FullWidthVideo
                video={{
                  id: featuredVideo?.videoId,
                }}
              />
            )}
          </div>

          <div className="container-lg space-y-page">
            <HybridGamesIntro />
            {asSeenOn && <BrandGrid items={asSeenOn} />}
            {arcade && <Arcade {...arcade} className="pb-12" />}
            {recruitment && <RecruitmentBlock {...recruitment} />}
          </div>

          <div className="container-lg flex w-full flex-col items-center space-y-3">
            <h2 className="text-center font-dharma text-6xl font-black uppercase italic text-cream md:text-8xl">
              Latest
            </h2>
            {latestNewsData && <LatestNewsCarousel items={latestNewsData} />}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Index;

export async function getStaticProps() {
  const queryClient = new QueryClient();

  await prefetchEvents(queryClient);

  await queryClient.prefetchQuery(
    ['query', homeQuery, homeQueryVars],
    async () => {
      return makeQuery(homeQuery, homeQueryVars, mapToHomepageContent);
    }
  );

  await queryClient.prefetchQuery(
    ['query', gamesQuery, gamesQueryVars],
    async () => {
      return makeQuery(gamesQuery, gamesQueryVars, mapToGamesList);
    }
  );

  await queryClient.prefetchQuery(
    ['query', latestNewsQuery, latestNewsQueryVars],
    async () => {
      return makeQuery(latestNewsQuery, latestNewsQueryVars, mapToNewsList);
    }
  );

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
    revalidate: 60,
  };
}
