import clsx from 'clsx';
import React from 'react';

import type { IVimeoEmbed } from '@/components/Primitive/VimeoEmbed/VimeoEmbed';
import { VimeoEmbed } from '@/components/Primitive/VimeoEmbed/VimeoEmbed';

interface IFullWidthVideo {
  video: IVimeoEmbed;
  className?: string;
}

const FullWidthVideo = ({ video, className }: IFullWidthVideo) => {
  return (
    <div className={clsx('aspect-video', className)}>
      <VimeoEmbed {...video} />
    </div>
  );
};

export default FullWidthVideo;
