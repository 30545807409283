import Image from 'next/image';
import React from 'react';
import Fade from 'react-reveal/Fade';

import spaceshipImg from '@/assets/images/spaceship.png';
import SmartLink from '@/components/Primitive/SmartLink';

interface IBrandGrid {
  items: {
    image: string;
    name: string;
    href: string;
  }[];
}

const BrandGrid = ({ items }: IBrandGrid) => {
  return (
    <div className="space-y-4 text-center md:space-y-12">
      <div className="container-md space-y-4 text-center md:space-y-12">
        <h2 className="text-xl font-medium text-cream">As seen in</h2>
        <Fade top cascade>
          <div className="mx-auto flex max-w-[640px] flex-wrap justify-around gap-6">
            {items.map((item) => (
              <SmartLink
                href={item.href}
                target="_blank"
                key={item.name}
                className="relative block h-[60px] max-w-[60px] sm:max-w-[120px]"
              >
                <Image
                  className="!relative object-contain"
                  src={item.image}
                  alt={item.name}
                  sizes="(min-width: 768px) 120px, 60px"
                  fill
                />
              </SmartLink>
            ))}
          </div>
        </Fade>
      </div>
      <div className="relative w-full">
        <Image
          src={spaceshipImg}
          alt=""
          width={1250}
          height={190}
          className="object-contain"
          sizes="(min-width: 768px) 1250px, 100vw"
        />
      </div>
    </div>
  );
};
export default BrandGrid;
