import clsx from 'clsx';
import Image from 'next/image';
import { event } from 'nextjs-google-analytics';
import React, { useState } from 'react';
import Tilt from 'react-parallax-tilt';

import arcadeImg from '@/assets/images/arcade.png';
import logo from '@/assets/images/logo.png';
import Button from '@/components/Primitive/Button/Button';

import VideoModal from '../VideoModal/VideoModal';

interface IArcade {
  videoId: string;
  image: string;
  ctaLabel?: string;
  ctaHref?: string;
  description?: string;
  className?: string;
}

const Arcade = ({
  videoId,
  image,
  description,
  ctaHref,
  ctaLabel,
  className,
}: IArcade) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
    event('video_play', {
      category: 'Homepage',
      label: 'Arcade Video Watched',
    });
  };

  return (
    <div
      className={clsx(
        'flex flex-col items-center space-y-4 pb-8 text-center md:pb-12',
        className
      )}
    >
      <h2 className="sr-only">Portal One Arcade</h2>
      <Image
        src={logo}
        width={140}
        height={140}
        alt="Portal One"
        sizes="130px"
      />
      <Image
        src={arcadeImg}
        width={620}
        height={170}
        sizes="(min-width:768px) 620px, 100vw"
        alt="Arcade"
      />
      {description && (
        <p className="max-w-[500px] text-lg text-blueAlt-500">{description}</p>
      )}

      {image && (
        <button
          className="hover-scale w-full overflow-hidden"
          onClick={handleOpen}
        >
          <Tilt
            trackOnWindow
            perspective={2000}
            tiltMaxAngleX={5}
            tiltMaxAngleY={5}
          >
            <Image
              className="w-full"
              src={image}
              width={1145}
              height={430}
              alt="Arcade Video"
              sizes="(min-width:768px) 1145px, 100vw"
            />
          </Tilt>
          <p className="sr-only">Watch the video</p>
        </button>
      )}

      {ctaHref && ctaLabel && (
        <Button
          onClick={() => {
            event('click', {
              category: 'Homepage',
              label: ctaLabel,
            });
          }}
          to={ctaHref}
          className="uppercase"
        >
          {ctaLabel}
        </Button>
      )}

      <VideoModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        videoId={videoId}
      />
    </div>
  );
};

export default Arcade;
