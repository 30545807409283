import { Dialog } from '@headlessui/react';
import React from 'react';
import { IoClose } from 'react-icons/io5';

import { VimeoEmbed } from '@/components/Primitive/VimeoEmbed/VimeoEmbed';

interface IVideoModal {
  videoId: string;
  open: boolean;
  onClose: () => void;
}

const VideoModal = ({ videoId, onClose, open }: IVideoModal) => {
  return (
    <Dialog className="relative z-50" open={open} onClose={onClose}>
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="container-md fixed inset-0 flex items-center justify-center">
        <Dialog.Panel className="relative w-full rounded ">
          <VimeoEmbed className="aspect-video w-full" id={videoId} />
          <div className="absolute -right-5 -top-5 p-2">
            <button className="hover-scale" onClick={onClose}>
              <IoClose className="text-3xl text-cream" />
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default VideoModal;
