import { gql } from 'graphql-request';

import type { SEO } from '@/types/seo';

interface HomepageContent extends SEO {
  featuredVideo: {
    videoId: string;
  };
  arcade: {
    videoId: string;
    image: string;
    description: string;
    ctaLabel: string;
    ctaHref: string;
  };
  asSeenOn: {
    id: string;
    name: string;
    image: string;
    href: string;
  }[];
  recruitment: {
    title: string;
    description: any;
    ctaLabel: string;
    ctaHref: string;
    image: string;
  };
}

export const createHomepageQuery = () => gql`
  query getHomepage {
    Homepage {
      featuredVideo {
        videoId
      }
      arcade {
        description
        videoId
        ctaLabel
        ctaHref
        videoImage {
          sizes {
            free {
              url
            }
          }
        }
      }
      asSeenOn {
        id
        title
        href
        logo {
          sizes {
            partner {
              url
            }
          }
        }
      }
      recruitment {
        title
        description
        ctaLabel
        ctaHref
        image {
          sizes {
            square2x {
              url
            }
          }
        }
      }
      seo {
        title
        description
        image {
          url
        }
      }
    }
  }
`;

export const mapToHomepageContent = (data: any): HomepageContent => {
  return {
    featuredVideo: {
      videoId: data.Homepage?.featuredVideo?.videoId || '',
    },
    arcade: {
      videoId: data.Homepage?.arcade?.videoId || '',
      image: data.Homepage?.arcade?.videoImage?.sizes?.free?.url || '',
      description: data.Homepage?.arcade?.description || '',
      ctaLabel: data.Homepage?.arcade?.ctaLabel || '',
      ctaHref: data.Homepage?.arcade?.ctaHref || '',
    },
    asSeenOn: data.Homepage.asSeenOn.map((item: any) => ({
      id: item.id,
      name: item.title,
      image: item.logo?.sizes?.partner?.url || '',
      href: item.href || '',
    })),
    recruitment: {
      title: data.Homepage?.recruitment?.title || '',
      description: data.Homepage?.recruitment?.description || [],
      ctaLabel: data.Homepage?.recruitment?.ctaLabel || '',
      ctaHref: data.Homepage?.recruitment?.ctaHref || '',
      image: data.Homepage?.recruitment?.image?.sizes?.square2x?.url || '',
    },
    seo: data.Homepage?.seo,
  };
};
