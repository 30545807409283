import 'keen-slider/keen-slider.min.css';

import clsx from 'clsx';
import { useKeenSlider } from 'keen-slider/react';
import type { ImageProps } from 'next/image';
import Image from 'next/image';
import React from 'react';

import SmartLink from '@/components/Primitive/SmartLink';
import useKeyPress from '@/hooks/use-key-press';
import AutoPlay from '@/lib/keen-slider-autoplay';
import WheelControls from '@/lib/keen-slider-wheel-controls';

type Game = {
  title: string;
  thumbnail: ImageProps['src'];
  href: string;
};

interface IGameCarousel {
  items: Game[];
  className?: string;
}

const GameCarousel = ({ items, className }: IGameCarousel) => {
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      loop: true,
      slides: {
        perView: 1.3,
        origin: 'center',
      },
      breakpoints: {
        '(min-width: 475px)': {
          slides: {
            perView: 2.3,
            origin: 'center',
          },
        },
        '(min-width: 1024px)': {
          slides: {
            perView: 3.3,
            origin: 'center',
          },
        },
      },
    },
    [WheelControls, AutoPlay]
  );

  useKeyPress('ArrowRight', () => {
    instanceRef.current?.next();
  });

  useKeyPress('ArrowLeft', () => {
    instanceRef.current?.prev();
  });

  return (
    <div ref={sliderRef} className={clsx('keen-slider', className)}>
      {[...items, ...items].map((item, i) => (
        <div key={`${item.title}:${i}`} className="keen-slider__slide">
          <SmartLink
            to="/hybrid-games"
            className="block transition-transform duration-150 hover:scale-101 focus:scale-101 active:scale-98"
          >
            <Image
              src={item.thumbnail}
              alt={item.title}
              className="object-contain"
              width={500}
              height={200}
              sizes="(min-width: 768px) 33vw, 80vw"
            />
          </SmartLink>
        </div>
      ))}
    </div>
  );
};

export default GameCarousel;
