import { isAfter, parseISO } from 'date-fns';

type Show = {
  title: string;
  headline: string;
  description: string;
  ctaLabel: string;
  ctaLink: string;
  imageLink: string;
  image: string;
  introTitle1: string;
  introTitle2: string;
};

export interface ShowSchedule extends Show {
  endTime: string; // ISO String when the event ends
}

export const getNextShow = (schedule: ShowSchedule[]) => {
  const now = new Date();
  let next = null;

  const sortedShows = schedule.slice().sort((a, b) => {
    return parseISO(a.endTime).getTime() - parseISO(b.endTime).getTime();
  });

  next = sortedShows.find((show) => isAfter(parseISO(show.endTime), now));
  if (!next) return null;

  return next;
};
