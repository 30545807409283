import clsx from 'clsx';
import type { ImageProps } from 'next/image';
import Image from 'next/image';
import React from 'react';

import Button from '@/components/Primitive/Button/Button';

interface IHeroProps {
  image: ImageProps['src'];
  alt?: string;
  ctaLabel?: string;
  ctaHref?: string;
  className?: string;
}

const Hero = ({ image, ctaLabel, ctaHref, alt, className }: IHeroProps) => {
  return (
    <div
      className={clsx(
        'relative flex max-h-screen min-h-[400px] w-full flex-col md:h-screen',
        className
      )}
    >
      <div className="absolute h-full w-full">
        <Image
          className="object-contain object-[50%,50%] sm:object-[50%,38%]"
          src={image}
          alt={alt || 'Portal One'}
          placeholder="blur"
          sizes="100vw"
          fill
          loading="eager"
        />
      </div>
      <div className="relative flex flex-1 items-end justify-center p-2 pb-5 md:pb-20 2xl:pb-[5vh]">
        {ctaHref && ctaLabel && (
          <Button size="sm-lg" innerClassName="uppercase" to={ctaHref}>
            {ctaLabel}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Hero;
