import { gql } from 'graphql-request';

import type { ShowSchedule } from '@/utils/get-next-dates';

export type Event = {
  title: string;
};

interface EventsContent {
  events: {
    schedule: ShowSchedule[];
  };
}

export const createEventsQuery = () => gql`
  query getEvents {
    Event {
      events {
        title
        headline
        time
        introTitle1
        introTitle2
        description
        ctaLabel
        ctaLink
        imageLink
        image {
          sizes {
            portrait {
              url
            }
          }
        }
      }
    }
  }
`;

export const mapToEventsContent = (data: any): EventsContent => {
  const schedule = data?.Event?.events;
  return {
    events: {
      schedule: schedule.map((s: any) => ({
        endTime: s?.time,
        title: s?.title,
        description: s?.description,
        ctaLabel: s?.ctaLabel,
        ctaLink: s?.ctaLink,
        imageLink: s?.imageLink,
        headline: s?.headline,
        image: s?.image?.sizes?.portrait?.url,
        introTitle1: s?.introTitle1,
        introTitle2: s?.introTitle2,
      })),
    },
  };
};
